<template>
    <div class="container px-3 mt-4">

        <div class="columns px-3">
            <div class="column">
                <h1 class="title has-text-primary-dark is-4">
                    Mi perfil
                </h1>
            </div>
        </div>

        <div class="columns mt-3">

            <!-- Loaded -->
            <div
                class="column is-4 is-offset-1 mx-mobile-auto is-relative to-avatar"
                v-if="ready && avatar !== false"
                :style="{'max-width': '290px', 'max-height': '290px'}"
            >

                <div
                    class="is-relative"
                >
                    <b-image
                        :src="avatar"
                        ratio="1by1"
                        rounded
                        class="avatar-profile"
                    >
                    </b-image>

                    <img src="/img/verified-check.svg"
                        style="position: absolute; bottom: 8px; right: 8px; width: 64px; height: 64px;"
                        alt="verificado"
                        title="Verificado"
                        v-if="$root.isIdentified && Array.isArray($root.user.user_verifications) && $root.user.user_verifications.length > 0 && $root.user.user_verifications[0].data.status === 'completed'"
                    />
                </div>

                <span class="icon has-text-info is-medium"
                @click="$router.push({ name: 'EditAvatar' })"
                :style="{
                    'position': 'absolute',
                    'left': '50%',
                    'top': '50%',
                    'margin-left': '-'+1.333/2+'em',
                    'margin-top': '-'+1.333/2+'em',
                    'transition': '.25s ease-out',
                    'opacity': '0'
                }">
                    <i class="fas fa-pen fa-lg is-clickable"></i>
                </span>
                
            </div>

            <div
                class="column is-4 is-offset-1"
                v-else-if="avatar === false"
            >

                <div
                    class="is-relative mx-auto"
                    style="height: 270px; width: 270px;"
                >
                    <avatar
                        :size="270"
                        :username="$root.user.name"
                        :initials="$root.user.name.charAt(0) + $root.user.lastname.charAt(0)"
                        color="#f5f5f5"
                        class="mx-auto avatar-null"
                    >
                    </avatar>

                    <img src="/img/verified-check.svg"
                        style="position: absolute; bottom: 8px; right: 8px; width: 64px; height: 64px;"
                        alt="verificado"
                        title="Verificado"
                        v-if="$root.isIdentified && Array.isArray($root.user.user_verifications) && $root.user.user_verifications.length > 0 && $root.user.user_verifications[0].data.status === 'completed'"
                    />
                </div>

                <span
                @click="$router.push({ name: 'EditAvatar' })"
                class="icon has-text-dark is-medium null-avatar">
                    <i class="fas fa-pen fa-lg is-clickable"></i>
                </span>

            </div>

            <!-- Loading -->
            <div class="column is-4 is-offset-1"
            v-else>
                <b-skeleton
                animated
                width="280px"
                height="280px"
                circle
                position="is-centered">
                </b-skeleton>
            </div>

            <div class="column is-7 is-flex is-flex-direction-column is-justify-content-center">
                <h1 class="subtitle is-2 has-text-centered mb-2">
                    {{ $root.user.name + ' ' + $root.user.lastname }}
                </h1>
                <h3 class="subtitle has-text-info-dark has-text-centered">
                    Registrado el {{ $moment($root.user.created_at).format('DD/MM/YYYY') }}
                </h3>
            </div>

        </div>

        <div class="columns mt-3">

            <div class="column is-5 order-2">
                <div class="box">
                    <b-field v-if="!changePassword"
                    class="is-flex is-justify-content-center">
                        <b-button type="is-info is-light"
                        rounded
                        label="Cambiar contraseña"
                        @click="changePassword = true"
                        >
                        </b-button>
                    </b-field>

                    <b-field v-if="changePassword"
                    class="mx-4"
                    label="Nueva contraseña"
                    label-position="on-border">
                        <b-input v-model="password"
                        type="password"
                        icon="key"
                        rounded
                        password-reveal
                        minlength="8"
                        >
                        </b-input>
                    </b-field>

                    <b-field v-if="changePassword"
                    class="is-flex is-justify-content-flex-end mx-4">
                        <b-button type="is-success"
                        icon-right="check"
                        rounded
                        label="Cambiar"
                        @click="updatePassword"
                        >
                        </b-button>
                    </b-field>
                </div>

                <div class="box has-text-centered">
                    
                    <h3 class="title is-3 mb-1"
                    v-if="ready"
                    >
                        {{ $root.transactions }}
                    </h3>
                    <b-skeleton v-else
                    position="is-centered"
                    height="32px"
                    width="32px"
                    animated
                    >
                    </b-skeleton>
                    <p class="has-text-primary-dark">
                        Transacciones
                    </p>

                    <div class="line-divider mt-0 mb-3" 
                    style="width: 60%"></div>
                    
                    <h3 class="title is-3 mb-1"
                    v-if="ready"
                    >
                        {{ accounts }}
                    </h3>
                    <b-skeleton v-else
                    position="is-centered"
                    height="32px"
                    width="32px"
                    animated
                    >
                    </b-skeleton>
                    <p class="has-text-primary-dark">
                        Cuentas bancarias
                    </p>

                    <div class="line-divider mt-0 mb-3" 
                    style="width: 60%"></div>

                    <h3 class="title is-3 mb-1"
                    v-if="ready"
                    >
                        {{ total }} €
                    </h3>
                    <b-skeleton v-else
                    position="is-centered"
                    height="32px"
                    width="64px"
                    animated
                    >
                    </b-skeleton>
                    <p class="has-text-primary-dark">
                        Total transferido
                    </p>

                </div>
            </div>

            <div class="column is-7">
                
                <div class="card mb-3">
                    <div class="card-header">
                        <p class="card-header-title is-size-5 mb-0">
                            Datos personales
                        </p>
                    </div>
                    <div class="card-content">
                        <div>
                            <ul class="has-text-centered">
                                <li>
                                    <b>Nombre:</b>
                                </li>
                                <li class="mb-2">{{ $root.user.name }}</li>

                                <li>
                                    <b>Apellidos:</b>
                                </li>
                                <li class="mb-2"> {{ $root.user.lastname }} </li>

                                <li>
                                    <b>{{ $root.user.document }}:</b>
                                </li>
                                <li class="mb-2"> {{ $root.user.dni }} </li>

                                <li>
                                    <b>Fecha de nacimiento:</b>
                                </li>

                                <li class="mb-2"
                                v-if="$root.user.birthdate !== null"> 
                                    {{ $moment($root.user.birthdate).format('DD MMMM [de] YYYY') }} 
                                </li>
                                <li class="mb-2 has-text-danger"
                                v-else>
                                    Actualiza este campo!
                                </li>

                                <li>
                                    <b>País de nacimiento:</b>
                                </li>
                                <li class="mb-2"> {{ $root.user.nacionality }} </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-header">
                        <p class="card-header-title is-size-5 mb-0">Datos de contacto</p>
                    </div>
                    <div class="card-content">
                        <div>
                            <ul class="has-text-centered">
                                <li>
                                    <b>Correo electrónico:</b>
                                </li>
                                <li class="mb-2"> {{ $root.user.email }} </li>

                                <li>
                                    <b>Télefono:</b>
                                </li>
                                <li class="mb-2"> {{ $root.user.phone }} </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-header">
                        <p class="card-header-title is-size-5 mb-0">Dirección de facturación</p>
                    </div>
                    <div class="card-content">
                        <div>
                            <ul class="has-text-centered">
                                <li>
                                    <b>País de residencia:</b>
                                </li>

                                <li class="mb-2"
                                v-if="$root.user.residence !== null"> 
                                    {{ $root.user.residence }} 
                                </li>
                                <li class="mb-2 has-text-danger"
                                v-else>
                                    Actualiza este campo!
                                </li>

                                <li>
                                    <b>Ciudad:</b>
                                </li>

                                <li class="mb-2" 
                                v-if="$root.user.city !== null"> 
                                    {{ $root.user.city }} 
                                </li>
                                <li class="mb-2 has-text-danger"
                                v-else>
                                    Actualiza este campo!
                                </li>

                                <li>
                                    <b>Piso:</b>
                                </li>

                                <li class="mb-2"
                                v-if="$root.user.flat !== null"> 
                                    {{ $root.user.flat }} 
                                </li>
                                <li class="mb-2 has-text-danger"
                                v-else>
                                    Actualiza este campo!
                                </li>

                                <li>
                                    <b>Calle:</b>
                                </li>

                                <li class="mb-2"
                                v-if="$root.user.direction !== null"> 
                                    {{ $root.user.direction }}
                                </li> 
                                <li class="mb-2 has-text-danger"
                                v-else>
                                    Actualiza este campo!
                                </li>

                                <li>
                                    <b>Código postal:</b>
                                </li>
                                <li class="mb-2"> {{ $root.user.post_code }} </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <AccountDeletionButton/>

            </div>

        </div>

        <b-modal v-model="modal.personal"
        scroll="keep"
        has-modal-card>

            <div class="modal-card" 
            style="max-width: 420px">
                <div class="modal-card-head">
                    <p class="my-0 modal-card-title">
                        Actualizar datos personales
                    </p>
                </div>

                <div class="modal-card-body">
                    <form>
                        <b-field label="Nombre"
                        label-position="on-border">
                            <b-input
                            rounded
                            type="text"
                            required 
                            v-model="personal.name"
                            :placeholder="$root.user.name"
                            >
                            </b-input>
                        </b-field>

                        <b-field label="Apellidos"
                        label-position="on-border">
                            <b-input
                            rounded
                            type="text"
                            required 
                            v-model="personal.lastname"
                            :placeholder="$root.user.lastname"
                            >
                            </b-input>
                        </b-field>

                        <b-field label="DNI/NIE/Pasaporte"
                        label-position="on-border">
                            <b-select v-model="personal.document"
                            placeholder="Tipo de documento"
                            style="max-width: 7rem"
                            rounded>

                                <option v-for="(item, index) in typeOfDocuments"
                                :key="'types-' + index"
                                :value="item">
                                    {{ item }}
                                </option>

                            </b-select>

                            <b-input
                            rounded
                            type="text"
                            required 
                            v-model="personal.dni"
                            :placeholder="$root.user.dni"
                            >
                            </b-input>
                        </b-field>

                        <b-field label="Fecha de nacimiento"
                        label-position="on-border">
                            <b-datepicker
                            :max-date="$moment().subtract(18, 'year')._d"
                            :placeholder="$moment($root.user.birthdate).format('L')"
                            v-model="personal.birthdate"
                            rounded
                            required
                            icon="calendar"
                            >
                            </b-datepicker>
                        </b-field>

                        <b-field label="País de nacimiento"
                        label-position="on-border">
                            <b-input
                            type="text"
                            rounded
                            required 
                            v-model="personal.nacionality"
                            :placeholder="$root.user.nacionality"
                            >
                            </b-input>
                        </b-field>
                    </form>
                </div>

                <div class="modal-card-foot">
                    <b-button type="is-success"
                    rounded
                    icon-right="check"
                    class="ml-auto"
                    @click="sendPersonal">
                        Actualizar
                    </b-button>
                </div>
            </div>

        </b-modal>

        <b-modal scroll="keep"
        v-model="modal.contact"
        has-modal-card>

            <div class="modal-card" 
            style="max-width: 420px">
                <div class="modal-card-head">
                    <p class="my-0 modal-card-title">
                        Actualizar datos de contacto
                    </p>
                </div>

                <div class="modal-card-body">
                    <form>
                        <b-field label="Télefono"
                        label-position="on-border">
                            <b-input
                            rounded
                            type="text"
                            required 
                            v-model="contact.phone"
                            :placeholder="$root.user.phone"
                            >
                            </b-input>
                        </b-field>

                        <b-field label="Correo electrónico"
                        label-position="on-border">
                            <b-input
                            rounded
                            type="email"
                            required 
                            v-model="contact.email"
                            :placeholder="$root.user.email"
                            >
                            </b-input>
                        </b-field>
                    </form>
                </div>

                <div class="modal-card-foot">
                    <b-button type="is-success"
                    rounded
                    icon-right="check"
                    class="ml-auto"
                    @click="sendContact">
                        Actualizar
                    </b-button>
                </div>
            </div>

        </b-modal>

        <b-modal scroll="keep"
        v-model="modal.direction"
        has-modal-card>

            <div class="modal-card" 
            style="max-width: 420px">
                <div class="modal-card-head">
                    <p class="my-0 modal-card-title">
                        Actualizar dirección de facturación
                    </p>
                </div>

                <div class="modal-card-body">
                    <form>
                        <b-field label="País de residencia"
                        label-position="on-border">

                            <b-select v-model="direction.residence" 
                            rounded
                            expanded
                            :placeholder="$root.user.residence"
                            icon="globe">

                                <option :value="item"
                                v-for="item in ueCountries"
                                :key="'country-'+item"
                                >
                                {{ item }}
                                </option>
                            </b-select>

                        </b-field>

                        <b-field label="Ciudad"
                        label-position="on-border">

                            <b-input
                            type="text"
                            rounded
                            :placeholder="$root.user.city"
                            v-model="direction.city"
                            >
                            </b-input>

                        </b-field>

                        <b-field label="Código postal" 
                        label-position="on-border">

                            <b-input
                            type="text"
                            rounded
                            v-model="direction.post_code"
                            :placeholder="$root.user.post_code"
                            >
                            </b-input>

                        </b-field>

                        <b-field label="Calle"
                        label-position="on-border">
                            <b-input v-model="direction.direction"
                            type="text"
                            :placeholder="$root.user.direction"
                            rounded
                            required></b-input>
                        </b-field>

                        <b-field label="Piso"
                        label-position="on-border">
                            <b-input v-model="direction.flat"
                            :placeholder="$root.user.flat"
                            type="text"
                            rounded
                            required></b-input>
                        </b-field>

                        <b-field label="País de nacimiento"
                        label-position="on-border">
                            <b-input
                            v-model="direction.nacionality"
                            :placeholder="$root.user.nacionality"
                            rounded
                            required
                            >
                            </b-input>
                        </b-field>
                    </form>
                </div>

                <div class="modal-card-foot">
                    <b-button type="is-success"
                    rounded
                    icon-right="check"
                    class="ml-auto"
                    @click="sendDirection">
                        Actualizar
                    </b-button>
                </div>
            </div>

        </b-modal>

    </div>
</template>

<script>
import AccountDeletionButton from "./AccountDeletionRequest/AccountDeletionButton.vue";

export default {
    components: {AccountDeletionButton},
  watch: {
      modal: {
          deep: true,
          handler: function (){
              this.$parent.$parent.navbarZ();
          }
      }
  },
  methods: {

      loadAvatar(){
          this.$http.get('api/image/avatar/show',{
            params: {
                token: this.$root.auth.token
            }
          })
          .then(res => {
              this.avatar = res.data
          })
          .catch(error => {
              if(error.response.status == 404){
                this.avatar = false;
              }
          })
      }, 

      countAccount(){
          this.$http.get('api/bank/account/count', {
              params: {
                  token: this.$root.auth.token
              }
          })
          .then(res => {
              this.accounts = res.data;
              this.readyAccount = true;
          })
          .catch(error => {
              console.log(error.response);
          })
      },

      getTotal(){
          this.$http.get('api/transaction/total', {
              params: {
                  token: this.$root.auth.token
              }
          })
          .then(res => {
              this.total = res.data;
              this.readyTotal = true;
          })
      },

      sendPersonal(){

          const loader = this.$buefy.loading.open();
          this.modal.personal = false;

          this.$http('api/user/update-personal', {
              method: 'put',
              headers: {
                'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token,
              },
              data: {
                'name': this.personal.name,
                'lastname': this.personal.lastname,
                'dni': this.personal.dni,
                'document': this.personal.document,
                'nacionality': this.personal.nacionality,
                'birthdate': this.$moment(this.personal.birthdate).format('YYYY/MM/DD')
              }
          })
          .then(res => {
              this.$buefy.toast.open({
                position: 'is-top-right',
                type: 'is-success',
                duration: 3000,
                message: res.data.message
              })

              loader.close();
              this.$root.loadUser();
          })
          .catch(error => {

            loader.close();
            let errs = Object.values(error.response.data.errors);

            errs.forEach(item => {
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: item[0],
                    position: 'is-top-right',
                    duration: 3000
                })
            })

          })

      },

      sendContact(){

          const loader = this.$buefy.loading.open();
          this.modal.contact = false;

          this.$http('api/user/update-contact', {
              method: 'put',
              headers: {
                  'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token,
              },
              data: {
                  phone: this.contact.phone,
                  email: this.contact.email
              }
          })
          .then(res => {
              this.$buefy.toast.open({
                  position: 'is-top-right',
                  type: 'is-success',
                  duration: 3000,
                  message: res.data.message
              })

              this.$root.loadUser()
              loader.close();
          })
          .catch(error => {

            loader.close();
            let errs = Object.values(error.response.data.errors);

            errs.forEach(item => {
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: item[0],
                    position: 'is-top-right',
                    duration: 3000
                })
            })

          })

      },

      sendDirection(){

          const loader = this.$buefy.loading.open();
          this.modal.direction = false;

          this.$http('api/user/update-direction', {
              method: 'put',
              headers: {
                  'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token,
              },
              data: this.direction
          })
          .then(res => {
              this.$buefy.toast.open({
                  position: 'is-top-right',
                  type: 'is-success',
                  duration: 3000,
                  message: res.data.message
              })

              this.$root.loadUser()
              loader.close();
          })
          .catch(error => {

            loader.close();
            let errs = Object.values(error.response.data.errors);

            errs.forEach(item => {
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: item[0],
                    position: 'is-top-right',
                    duration: 3000
                })
            })

          })

      },

      updatePassword(){

          const loader = this.$buefy.loading.open();

          this.$http('api/user/password', {
            method: 'put',
            headers: {
                'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token,
            },
            data: {
                password: this.password
            }
          })
          .then(res => {
            loader.close();
            this.$buefy.toast.open({
                type: 'is-success',
                message: res.data.message,
                position: 'is-top-right',
                duration: 3000
            })
          })
          .catch(error => {
            loader.close();
            let errs = Object.values(error.response.data.errors);

            errs.forEach(item => {
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: item[0],
                    position: 'is-top-right',
                    duration: 3000
                })
            })
          })

      },
      syncInputsUpdate(){
          let date = this.$root.user.birthdate !== null ? this.$moment(this.$root.user.birthdate) : new Date

          this.contact.email = this.$root.user.email
          this.contact.phone = this.$root.user.phone

          this.personal.name = this.$root.user.name
          this.personal.lastname = this.$root.user.lastname
          this.personal.dni = this.$root.user.dni
          this.personal.document = this.$root.user.document
          this.personal.nacionality = this.$root.user.nacionality
          this.personal.birthdate = date._d

          this.direction.residence = this.$root.user.residence
          this.direction.city = this.$root.user.city
          this.direction.flat = this.$root.user.flat
          this.direction.direction = this.$root.user.direction
          this.direction.nacionality = this.$root.user.nacionality
          this.direction.post_code = this.$root.user.post_code
      }

  },

  computed: {
      ready(){
        return this.avatar !== null && this.readyAccount && this.readyTotal ? true : false;
      },
      isModal(){
        return this.modal.personal && this.modal.contact && this.modal.direction ? true : false;
      }
  },
  data () {
    return {
        avatar: null,
        accounts: null,
        total: null,
        readyAccount: false,
        readyTotal: false,

        ueCountries: 
        [
            'Alemania', 'Austria', 'Bélgica', 'Bulgaria', 'Chequia', 'Chipre', 'Croacia', 'Dinamarca', 'Eslovaquia', 'Eslovenia', 'España', 'Estonia', 'Finlandia', 'Francia', 'Grecia', 'Hungría', 'Irlanda', 'Italia', 'Letonia', 'Lituania', 'Luxemburgo', 'Malta', 'Países Bajos', 'Polonia', 'Portugal', 'Rumanía', 'Suecia' 
        ],

        modal: {
            personal: false,
            contact: false,
            direction: false,
        },
        changePassword: false,
        password: '',

        contact: {
            phone: this.$root.user.phone,
            email: this.$root.user.email
        },
        personal: {
            name: '',
            lastname: '',
            dni: '',
            document: null,
            nacionality: '',
            birthdate: null
        },
        direction: {
            residence: '',
            city: '',
            flat: '',
            direction: '',
            nacionality: '',
            post_code: ''
        },
        typeOfDocuments: 
        [
            'DNI', 'NIE', 'Pasaporte'
        ]
    }
  },
  mounted () {

      this.loadAvatar();
      this.countAccount();
      this.getTotal();

      this.syncInputsUpdate();

  },

  beforeMount () {
    if (! this.$root.isAuthenticate) {
      this.$router.push({name: 'Login'});
    }
  },
    
  name: 'Profile',

}
</script>

<style lang="scss">

    .to-avatar:hover{
        img{
            filter: brightness(.5);
        }
        span.icon{
            opacity: 1 !important;
        }
    }

    .to-avatar{
        img{
            transition: .25s ease-out;
        }
    }

    .null-avatar{
        margin-left: auto;
        margin-right: auto;
        display: block !important;

        position: relative;
        top: -151px;
        right: -2px;
        text-align: center;

        opacity: 0;
        transition: .3s ease-out;
    }

    .avatar-null:hover + .null-avatar, 
    .avatar-null > span:hover + .null-avatar,
    .null-avatar:hover{
        opacity: 1;
    }

</style>